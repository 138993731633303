
import '../App.css';
import {useCallback, useEffect, useState} from "react";
import Autocomplete from 'react-autocomplete';
import {Text} from "../elem/Elem";
import {
  getItemFromListByProp, ignoreLangString,
  numberWithCommas,
  performApi
} from "../utils/Util";
import {
  Button,
  Dropdown,
  DropdownButton,
  Form,
  Modal,
  Table
} from "react-bootstrap";
import {
  FaTrashAlt,
  FaPlus,
  FaMinus,
  FaSave
} from "react-icons/fa";
import ReactTooltip from "react-tooltip";

function Craft({theme}) {

  const token = localStorage.getItem("token")
  const brightThemeTextColor = '#000b44';
  const lightThemeInputBg = ''

  const [loadPage, setLoadPage] = useState(false)

  const [searchValue, setSearchValue] = useState('')
  // result of search for craft
  const [itemList, setItemList] = useState([])
  // list of chosen items for craft
  const [craftList, setCraftList] = useState([])
  // craft for chosen items (some resources can be expanded)
  const [craftResourcesList, setCraftResourcesList] = useState([])
  // hidden element which can be expanded (actual for craftable resources)
  const [hiddenList, setHiddenList] = useState([])
  // total resource calculation
  const [totalCalcCraftResourcesList, setTotalCalcCraftResourcesList] = useState([])
  // total price for all resource in calculation
  const [total, setTotal] = useState(0)

  const [craftName, setCraftName] = useState('')
  const [localItemCache, setLocalItemCache] = useState([])
  const [savedList, setSavedList] = useState([])
  const [show, setShow] = useState(false);

  const [srch, setSrch] = useState([])


  const handleClose = () => setShow(false);

  const getCraftRequest = async (text) => {
    return performApi('POST', '/craft/search', text)
  }

  const getItemListRequest = async (text) => {
    return performApi('GET', '/craft/list/' + text)
  }

  const getItemListByTypeRequest = async (types) => {
    return performApi('POST', '/craft/list/type', JSON.stringify(types))
  }

  useEffect(() => {
    if (!loadPage) {
      getUserPrices()
      setLoadPage(true)
    }
  }, []);

  const getUserPrices = async () => {
    let res = await performApi('GET', '/user/price')
    let fnl = res.map(tmpItem => {
      return {...tmpItem, exists:(tmpItem.exists ? tmpItem.exists : 0)}
    })
    setSavedList(fnl)
    setLocalItemCache(fnl)
    return res;
  }

  const getCraft = async (text, count) => {
    let itemName = (text.val ? text.val.name : text);
    let res = await getCraftRequest(itemName);
    let newCraftList
    if (count) {
      newCraftList = [...craftList.filter(e => e.name !== itemName), {...res, count:count}]
    } else {
      let existsObj = getItemFromListByProp(craftList, itemName, 'name');
      if (!existsObj) {
        newCraftList = extendCraftListData([...craftList, res])
      } else {
        return
      }
    }
    setCraftList(newCraftList)
    calcResourcesForCraft(newCraftList)
  }

  const extendCraftListData = (newCraftList) => {
    return newCraftList.map(tmpItem => {
      return {...tmpItem, count:(tmpItem.count ? tmpItem.count : 1)}
    })
  }

  const changeCraftListData = (name, count) => {
    setCraftList(craftList.map(tmpItem => {
      if (tmpItem.name === name) {
        return {...tmpItem, count:count}
      }
      return tmpItem
    }))
  }

  useEffect(() => {
    if (searchValue) {
      const timeOutId = setTimeout(() => getItemList(ignoreLangString(searchValue)), searchValue.length > 1 ? 150 : 0);
      return () => clearTimeout(timeOutId);
    }
  }, [searchValue]);

  const getItemList = (text) => {
    if (text.length > 1) {
      getItemListRequest(text).then(res => {
        setItemList(res)
      })
    } else {
      setItemList([])
    }
  }

  const removeFromCraftList = (item) => {
    let newCraftList = craftList.filter(e => e.name !== item.name)
    setCraftList(newCraftList)
    calcResourcesForCraft(newCraftList)
  }

  const calcResourcesForCraft = (craftList) => {
    let items = []
    craftList.forEach(e => {
      Object.keys(e.resources).forEach(function (key){
        let newValue = e.resources[key]
        let oldValue = getItemFromListByProp(items, key, 'name')
        if (oldValue) {
          items = items.map(tmpItem => {
            if (tmpItem.name === key) {
              return {...tmpItem, count:(tmpItem.count + (newValue.count * e.count))}
            }
            return tmpItem
          })
        } else {
          let craftItem = createCraftResourceItem(key, e, newValue)
          items = [...items, craftItem]
        }
      })
    })
    initHiddenRowInResourceTable(items.sort((a, b) => a.name > b.name ? 1 : -1))
    setTotalCalcCraftResourcesList(items.map(tmpItem => {
      return {...tmpItem, changed:(tmpItem.changed ? tmpItem.changed : false)}
    }))
    calcTotal(items, localItemCache)
    setCraftResourcesList(items.sort((a, b) => a.name > b.name ? 1 : -1))
  }

  const createCraftResourceItem = (name, upperItem, act) => {
    return { name : name,
      count : (act.count * upperItem.count) / (upperItem.craft ? upperItem.craft.resultCount ? upperItem.craft.resultCount : 1 : 1),
      craftable : (!!act.craft),
      imgLink : act.imgLink,
      craft : act.craft,
      hidden: true,
      price: act.price,
      exists: 0,
      uuid: act.uuid,
      itemId: act.itemId
    }
  }

  const initHiddenRowInResourceTable = (items) => {
    let hiddens = []
    items.forEach(e => {
      hiddens = updHiddenList(hiddens, e, e.name)
    })
    setHiddenList(hiddens)
  }

  const initHiddenRowInResourceTableObject = (items) => {
    let hiddens = []
    Object.keys(items).forEach(function (el){
      let item = items[el]
      hiddens = updHiddenList(hiddens, item, el)
    })
    return hiddens
  }

  const updHiddenList = (hiddensList, item, name) => {
    let hiddens
    if (!!item.craft) {
      hiddens = [...hiddensList, { ...item, name : name, hidden : true}]
      hiddens = hiddens.concat(initHiddenRowInResourceTableObject(item.craft.resources))
    } else {
      hiddens = [...hiddensList, { ...item, name : name, hidden : false}]
    }
    return hiddens
  }

  const getItemFromCache = (d, cachedList) => {
    let itemFromUserCache = getItemFromListByProp(savedList, d.name, 'name')
    let lclCache = cachedList ? cachedList : localItemCache
    let lclCachedItem = lclCache ? getItemFromListByProp(lclCache, d.name, 'name') : undefined
    let itemFromCache = lclCachedItem ? lclCachedItem : itemFromUserCache
    return itemFromCache
  }

  const calcTotal = (totalCalcCraftResourcesList, cachedList) => {
    let totalSum = 0
    totalCalcCraftResourcesList.forEach(e => {
      let itemFromCache = getItemFromCache(e, cachedList)
      let additionalSum = itemFromCache ? (e.count - itemFromCache.exists) * itemFromCache.price : (e.count - e.exists) * e.price
      totalSum = totalSum + (additionalSum > 0 ? additionalSum : 0)
    })
    setTotal(totalSum)
  }

  const expandCraft = (item) => {
    setHiddenList(hiddenList.map(tmpItem => {
      if (tmpItem.uuid === item.uuid) {
        let newStat = !tmpItem.hidden
        if (newStat) {
          let cleanedTotalCalcCraftResourcesList = removeFromTotalCalcCraftResourcesList(item)
          let finalList = cleanedTotalCalcCraftResourcesList.filter(e => e.count > 0).sort((a, b) => a.name > b.name ? 1 : -1)
          setTotalCalcCraftResourcesList(finalList)
          calcTotal(finalList, localItemCache)
        } else {
          let cleanedTotalCalcCraftResourcesList = expandItemInTotalCalcCraftResourcesList(item)
          let finalList = cleanedTotalCalcCraftResourcesList.filter(e => e.count > 0).sort((a, b) => a.name > b.name ? 1 : -1)
          setTotalCalcCraftResourcesList(finalList)
          calcTotal(finalList, localItemCache)
        }
        return {...tmpItem, hidden:newStat}
      }
      return tmpItem
    }))
  }

  const removeFromTotalCalcCraftResourcesList = (item) => {
    let subcrafts = calcResourceTree(item)
    let finalList = []
    totalCalcCraftResourcesList.forEach(e => {
      subcrafts.forEach(s => {
        if (!finalList.filter(fle => fle.name === e.name)[0]) {
          if (e.name === s.name) {
            finalList = [...finalList, {...e, count: (e.count - s.count)}]
          }
        }
      })
    })
    totalCalcCraftResourcesList.forEach(e => {
      if (!finalList.filter(fle => fle.name === e.name)[0]) {
        if (e.name === item.name) {
          finalList = [...finalList,  {...e, count: (e.count + item.count)}]
        } else {
          finalList = [...finalList, e]
        }
      }
    })
    if (!finalList.filter(fle => fle.name === item.name)[0]) {
      finalList = [...finalList, item]
    }
    return finalList
  }

  const expandItemInTotalCalcCraftResourcesList = (item) => {
    let finalList = []
    let resourceTree = calcResourceTree(item)
    let filteredTotalCalcCraftResourcesList = totalCalcCraftResourcesList.filter(e => {
      return !(e.name === item.name && e.count <= item.count);
    })
    filteredTotalCalcCraftResourcesList.forEach(e => {
      if (e.name === item.name) {
        finalList = [...finalList,  {...e, count: (e.count - item.count)}]
      }
      resourceTree.forEach(rt => {
        let willBeInFuture = filteredTotalCalcCraftResourcesList.filter(fle => fle.name === rt.name)[0] || resourceTree.filter(fle => fle.name === e.name)[0]
        if (e.name === rt.name) {
          if (!finalList.filter(fle => fle.name === e.name)[0]) {
            finalList = [...finalList, {...e, count: (e.count + rt.count)}]
          }
        } else if (!willBeInFuture) {
          if (!finalList.filter(fle => fle.name === rt.name)[0]) {
            finalList = [...finalList, createCraftResourceItem(rt.name, { count:1 } , rt)]
          }
          if (!finalList.filter(fle => fle.name === e.name)[0]) {
            if (e.name === item.name) {
              finalList = [...finalList,  {...e, count: (e.count - item.count)}]
            } else {
              finalList = [...finalList, e]
            }
          }
        }
      })
    })
    filteredTotalCalcCraftResourcesList.forEach(e => {
      if (!finalList.filter(fle => fle.name === e.name)[0]) {
        finalList = [...finalList, e]
      }
    })
    return finalList
  }

  const calcResourceTree = (item) => {
    let finalList = []
    Object.keys(item.craft.resources).forEach((el) => {
      let subItem = item.craft.resources[el]
      let calculatedItem = { ...subItem, name : el, count: ((subItem.count * item.count) / (item.craft ? item.craft.resultCount ? item.craft.resultCount : 1 : 1)), uuid : subItem.uuid}
      let getHiddenProp = getItemFromListByProp(hiddenList, subItem.uuid, 'uuid')
      if (!subItem.craft || getHiddenProp.hidden) {
        finalList = [...finalList, calculatedItem]
      } else {
        finalList = finalList.concat(calcResourceTree(calculatedItem))
      }
    })
    let agreg = []

    finalList.forEach(e => {
      let existsInAgreg = getItemFromListByProp(agreg, e.name, 'name')
      if (!existsInAgreg) {
        agreg = [...agreg, e]
      } else {
        agreg = agreg.filter(ag => ag.name !== existsInAgreg.name)
        agreg = [...agreg, {...e, count: e.count + existsInAgreg.count}]
      }
    });

    return agreg;
  }

  const getCraftCountValue = (d) => {
    let item = getItemFromListByProp(craftList, d.name, 'name');
    return item ? item.count : 1
  }

  const changeCraftCountValue = (d, val) => {
    let value = parseInt(val.target.value) > 0 ? parseInt(val.target.value) : 1;
    changeCraftListData(d.name, value)
    getCraft(d.name, value)
  }

  const getTotalCalcCraftValue = (d, propName) => {
    let itemFromCache = getItemFromCache(d)
    let item = getItemFromListByProp(totalCalcCraftResourcesList, d.name, 'name');
    return itemFromCache ? itemFromCache[propName] : item[propName]
  }

  const changeTotalCalcCraftValue = (d, propName, val) => {
    let incomingValue = val.target.value.replaceAll(",","")
    let value = parseInt(incomingValue) >= 0 ? parseInt(incomingValue) : '';
    let changedTotalCalcCraftResourcesList = totalCalcCraftResourcesList.map(tmpItem => {
      if (tmpItem.name === d.name) {
        let fnl = {...tmpItem, [propName]:value}
        if (propName === 'price') {
          fnl = {...fnl, changed:true}
        }
        return fnl
      }
      return tmpItem
    })
    let cache = []
    if (localItemCache.filter(li => li.name === d.name).length === 0) {
      let localPrice = d.price
      let localAmount = d.exists
      if (propName === 'price') {
        localPrice = incomingValue
      }
      if (propName === 'exists') {
        localAmount = incomingValue
      }
      let finalLocalItemCache = [...localItemCache, {name:d.name, price:localPrice, exists:localAmount}]
      cache = finalLocalItemCache
      setLocalItemCache(finalLocalItemCache)
    } else {
      let lic = getItemFromListByProp(localItemCache, d.name, 'name')
      let filtered = localItemCache.filter(li => li.name !== d.name)
      let localPrice = lic.price
      let localAmount = lic.exists
      if (propName === 'price') {
        localPrice = incomingValue
      }
      if (propName === 'exists') {
        localAmount = incomingValue
      }
      let finalLocalItemCache = [...filtered, {name:d.name, price:localPrice, exists:localAmount}]
      cache = finalLocalItemCache
      setLocalItemCache(finalLocalItemCache)
    }
    setTotalCalcCraftResourcesList(changedTotalCalcCraftResourcesList)
    calcTotal(changedTotalCalcCraftResourcesList, cache)
  }

  const renderCraftResourcesTable = (d , padding) => {
    let dp = padding ? padding + 1 : 0;
    let hiddenObj = getItemFromListByProp(hiddenList, d.uuid, 'uuid')
    let isHidden = hiddenObj ? hiddenObj.hidden : false
    return <tr>
      <td style={{paddingLeft: (dp > 0 ? 35 : 0), }}>
        <td><img className={theme ? "Img" : "ImgLght"} src={d.imgLink} alt=""/></td>
        <td><div style={{width:250}}><a href={'https://asterios.tm/index.php?cmd=bd#item.info.' + d.itemId} target="_blank" style={{color:theme ? '#f3ca00' : brightThemeTextColor, textDecoration: 'none'}}>{d.name}   </a></div></td>
        <td><div style={{width:70, textAlign:'right'}}><Text style={{color:theme ? '' : brightThemeTextColor}}>{d.count}   </Text></div></td>
        <td>{d.craftable && <Button style={{marginLeft:10, border: theme ? '' : '1px solid black'}} variant={'light'} size={'xsm'} onClick={() => expandCraft(d)}>{isHidden ? <FaPlus /> : <FaMinus />}</Button>}</td>
          {d.craftable &&
          <div style={{flexDirection:'row'}} id={d.uuid} hidden={isHidden}>
              {
                Object.keys(d.craft.resources).map((e) => {
                  let subItem = d.craft.resources[e]
                  let subCraftItem = createCraftResourceItem(e, d, subItem)
                  return renderCraftResourcesTable(subCraftItem, dp + 1)
                })
              }
          </div>
          }
      </td>
    </tr>
  }

  const renderFinalCalCraftResourcesTable = (d) => {
    let itemFromCache = getItemFromCache(d)
    let totalSum = itemFromCache ? (d.count - itemFromCache.exists) * itemFromCache.price : (d.count - d.exists) * d.price;

    return <tr>
      <td>
        <div style={{display: 'flex'}}>
          <div><img className={theme ? "Img" : "ImgLght"} src={d.imgLink} alt=""/></div>
          <a href={'https://asterios.tm/index.php?cmd=bd#item.info.' + d.itemId} target="_blank" style={{width:250, color:theme ? '#f3ca00' : brightThemeTextColor, textDecoration: 'none'}}>{d.name}</a>
        </div>
      </td>
      <td><div style={{textAlign:'right'}}><Text style={{color:theme ? '' : brightThemeTextColor, paddingRight:2}}>{d.count} </Text></div></td>
      <td><div style={{width:30}}>{token && d.changed &&  <Button  variant={'light'} size={'xsm-remove'} onClick={() => saveToLocalPrices(d)}>{<FaSave />}</Button>}</div></td>
      <td>{<Form.Control size={'xsm'} style={{width:100, backgroundColor:(theme ? '#cebb9f' : lightThemeInputBg)}} value={numberWithCommas(getTotalCalcCraftValue(d, 'price'))} onChange={(val) => changeTotalCalcCraftValue(d, 'price', val)}/>}</td>
      <td>{<Form.Control size={'xsm'} style={{width:70, backgroundColor:(theme ? '#cebb9f' : lightThemeInputBg)}} value={numberWithCommas(getTotalCalcCraftValue(d, 'exists'))} onChange={(val) => changeTotalCalcCraftValue(d, 'exists', val)}/>}</td>
      <td><Text style={{width:150, color:theme ? '' : brightThemeTextColor}}>{numberWithCommas(totalSum > 0 ? totalSum : 0)}</Text></td>
    </tr>
  }

  const saveToLocalPrices = (d) => {
    let itemToSave
    let changedTotalCalcCraftResourcesList = totalCalcCraftResourcesList.map(tmpItem => {
      if (tmpItem.name === d.name) {
        itemToSave = {...tmpItem, changed:false}
        return itemToSave
      }
      return tmpItem
    })
    setTotalCalcCraftResourcesList(changedTotalCalcCraftResourcesList)
    let body = {
      price: d.price,
      name: d.name
    }
    performApi('POST', '/user/price/saveorupdate', JSON.stringify(body))
  }

  const saveCraft = () => {
    setShow(true)
  }

  const realSaveCraft = () => {
    handleClose()
    let body = {
      craftItems: JSON.stringify(craftList),
      craftResources: JSON.stringify(craftResourcesList),
      hiddenList: JSON.stringify(hiddenList),
      finalCalculation: JSON.stringify(totalCalcCraftResourcesList),
      localPrices: JSON.stringify(localItemCache),
      craftName: craftName
    }
    let jsonBody = JSON.stringify(body)
    performApi('POST', '/user/craft/add', jsonBody)
  }

  const renderWindowForSaveCraft = () => {
    return <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Set the name for craft</Modal.Title>
      </Modal.Header>
      <Form.Control size={'xsm'} value={craftName} onChange={(val) => setCraftName(val.target.value)}/>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={realSaveCraft}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  }

  const searchItemByType = async (types) => {
    setSrch([])
    let res = await getItemListByTypeRequest(types)
    setSrch(res)
  }

  const searchElem = (name, types) => {
    return <DropdownButton id="dropdown-basic-button" title={name} drop={'end'} onClick={() => searchItemByType(types)} variant="secondary" size={'sm'}>
      {srch.length > 0 &&
      <div className={'divscroll'} style={{maxHeight: 500, overflow:'scroll', overflowX:'hidden', background:'white'}}>
        {srch.map((d) => {
          return <Dropdown.Item onClick={() => getCraft(d.name)}>
            <div style={{display: 'flex'}}>
              <img className={theme ? "Img" : "ImgLght"} src={d.imgLink} alt=""/>
              <div style={{width:30}}>
                <img style={{textAlign:'left', background:'black'}} className={"Img"} src={'https://asterios.tm/'+d.grade} alt=""/>
              </div>
              {d.name}
            </div>
          </Dropdown.Item>
        })}</div>}
    </DropdownButton>
  }

  const renderSearchMenu = () => {
    return <Dropdown style={{paddingRight:5}} drop={'end'}>
      <Dropdown.Toggle className="main-style" id="dropdown-basic" variant="secondary" size={'sm'} style={{width:80}}>Search</Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown drop={'end'}>
          <Dropdown.Toggle className="main-style" id="dropdown-basic" variant="secondary" size={'sm'} style={{width:80}}>weapon</Dropdown.Toggle>

          <Dropdown.Menu >
            {searchElem('swords', ['swords_1h_p', 'swords_1h_m', 'swords_2h'])}
            {searchElem('bows', ['bows'])}
            {searchElem('blunts', ['blunts_1h_p', 'blunts_1h_m', 'blunts_2h_p', 'blunts_2h_m'])}
            {searchElem('daggers', ['daggers'])}
            {searchElem('polearms', ['polearms'])}
            {searchElem('fists', ['dualfists'])}
            {searchElem('others', ['etc_weapons'])}
          </Dropdown.Menu>
        </Dropdown>
        <Dropdown drop={'end'}>
          <Dropdown.Toggle className="main-style" id="dropdown-basic" variant="secondary" size={'sm'} style={{width:80}}>armor</Dropdown.Toggle>

          <Dropdown.Menu>
            {searchElem('helmets', ['helmets'])}
            {searchElem('boots', ['boots'])}
            {searchElem('gloves', ['gloves'])}
            {searchElem('upper', ['light_up', 'heavy_up', 'robe_up'])}
            {searchElem('low', ['light_low', 'heavy_low', 'robe_low'])}
            {searchElem('full-armor', ['light_full', 'heavy_full', 'robe_full'])}
            {searchElem('shields', ['shields'])}
            {searchElem('sigils', ['sigils'])}
          </Dropdown.Menu>
        </Dropdown>
        <Dropdown drop={'end'} variant="secondary" size={'sm'}>
          <Dropdown.Toggle className="main-style" id="dropdown-basic" variant="secondary" size={'sm'} style={{width:80}}>jewelry</Dropdown.Toggle>

          <Dropdown.Menu>
            {searchElem('necklaces', ['necklaces'])}
            {searchElem('earrings', ['earrings'])}
            {searchElem('rings', ['rings'])}
          </Dropdown.Menu>
        </Dropdown>

        <Dropdown drop={'end'}>
          <Dropdown.Toggle className="main-style" id="dropdown-basic" variant="secondary" size={'sm'} style={{width:80}}>shots</Dropdown.Toggle>

          <Dropdown.Menu>
            {searchElem('grade D', ['shots_d'])}
            {searchElem('grade C', ['shots_c'])}
            {searchElem('grade B', ['shots_b'])}
            {searchElem('grade A', ['shots_a'])}
            {searchElem('grade S', ['shots_s'])}
            {/*{searchElem('full-armor', ['dualfists'])}*/}
          </Dropdown.Menu>
        </Dropdown>
      </Dropdown.Menu>
    </Dropdown>
  }

  const calcPricePerSinge = useCallback((d, totalCount) => {
    let finalPrice = 0;

    Object.keys(d.resources).forEach(function (key){
      let e = d.resources[key]
      let itemFromCache = getItemFromListByProp(localItemCache ? localItemCache : totalCalcCraftResourcesList, key, 'name')
      finalPrice =  finalPrice + (itemFromCache ? e.count * d.count * itemFromCache.price : e.count * d.count * e.price);
    })

    return Math.round(finalPrice / totalCount * 100) / 100;
  }, [total])

  return (
      <div className="Craft">
        {renderWindowForSaveCraft()}
        <div>
          <Text style={{display: 'flex',  justifyContent:'center', alignItems:'center', color:theme ? '' : brightThemeTextColor}}>Craft calculator for Asterios</Text>
          <div style={{display: 'flex',  justifyContent:'center', alignItems:'center'}}>
            {renderSearchMenu()}
            <Autocomplete
                inputProps={{placeholder:'Search', style:{width: 350}}}
                value={searchValue}
                getItemValue={item => item.name}
                renderItem={(item) =>
                    <div style={{textAlign: "left", width: 350, cursor: 'pointer'}}>
                      <img className={theme ? "Img" : "ImgLght"} src={item.imgLink} alt=""/>
                      {item.name}
                    </div>
                }
                onChange={(event, vl) => setSearchValue(vl)}
                onSelect={(event, val) => getCraft({ val })}
                items={itemList}/>
          </div>
          {token && craftList.length > 0 &&
            <div style={{display: 'flex',  justifyContent:'center', alignItems:'center'}}>
              <Button variant="secondary" size={'sm'} onClick={() => saveCraft()}>save craft</Button>
            </div>
          }
          <div style={{display: 'flex'}}>
            {craftList.length > 0 &&
            <div style={{width:450}}>
              <br/>
              <Table hover size="sm" borderless={true}>
                <thead>
                <tr>
                  <th></th>
                  <th></th>
                  <th></th>
                </tr>
                </thead>
                <tbody>
                {craftList.sort((a, b) => a.name > b.name ? 1 : -1).map((d) => (
                    <tr>
                      <td>
                        <div style={{display: 'flex'}}>
                          <div data-tip data-for={d.name}>
                            <img className={theme ? "Img" : "ImgLght"} src={d.imgLink} alt=""/>
                            <ReactTooltip id={d.name} place="top" effect="solid">
                              {d.pdef && <div>pDef : {d.pdef}</div>}
                              {d.mdef && <div>mDef : {d.mdef}</div>}
                              {d.atack && <div>attack : {d.atack}</div>}
                              {d.shots && <div>shots : {d.shots}</div>}
                              {d.speed && <div>speed : {d.speed}</div>}
                              {d.type && <div>type : {d.type}</div>}
                              {d.weight && <div>weight : {d.weight}</div>}
                            </ReactTooltip>
                          </div>
                          <div style={{width:30}}>
                            <img style={{textAlign:'left', background:'black'}} className={"Img"} src={'https://asterios.tm/'+d.grade} alt=""/>
                          </div>
                        </div>
                      </td>
                      <td>
                          <Text style={{color:theme ? '' : brightThemeTextColor}}>{d.name} {d.resultCount > 1 && '(' + d.resultCount + ' shots)'}</Text>
                      </td>

                      <td>
                        <div style={{marginLeft:'auto',marginRight:0, paddingTop:5, display:'flex'}}>
                          <Form.Control size={'xsm'} style={{width:50, backgroundColor:(theme ? '#cebb9f' : lightThemeInputBg)}} value={getCraftCountValue(d)} onChange={(val) => changeCraftCountValue(d, val)}/>
                          <Button variant={'danger'} size={'xsm-remove'} onClick={() => removeFromCraftList(d)}><FaTrashAlt /></Button>
                        </div>
                      </td>
                    </tr>
                ))}
                </tbody>
              </Table>
            </div>}
            <div style={{marginLeft:'auto', display: 'flex', marginRight:50}}>{craftResourcesList.length > 0 &&
            <div style={{display: 'flex'}}>
              <div style={{paddingRight:50}}>
                <br/>
                <Table hover size="sm" borderless={true}>
                  <thead>
                  <tr>
                    <th></th>
                  </tr>
                  </thead>
                  <tbody>
                    {craftResourcesList.map((d) => renderCraftResourcesTable(d))}
                  </tbody>
                </Table>
              </div>
              <div>
                <Table hover size="sm" borderless={true}>
                  <thead>
                  <tr>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th><Text style={{color:theme ? '' : brightThemeTextColor}}>price</Text></th>
                    <th><Text style={{color:theme ? '' : brightThemeTextColor}}>available</Text></th>
                    <th><Text style={{color:theme ? '' : brightThemeTextColor}}>total adena for res</Text></th>
                  </tr>
                  </thead>
                  <tbody>
                    {totalCalcCraftResourcesList.map((d) => renderFinalCalCraftResourcesTable(d))}
                    <tr>
                      <td colSpan="3"></td>
                      <td colSpan="2"><div style={{textAlign:'right', color:'red'}}>TOTAL PRICE : </div></td>
                      <td><div style={{color:'red'}}>{numberWithCommas(total)}</div></td>
                    </tr>
                    {craftList.map((e) => {
                      if (e.resultCount > 1) {
                        let totalCount = e.resultCount * e.count
                        return <tr>
                          <td colSpan="5">
                            <div style={{display:'flex', justifyContent: 'flex-end'}}>
                              <div style={{alignSelf:'center'}}>
                                <img className={theme ? "Img" : "ImgLght"} src={e.imgLink} alt=""/>
                              </div>
                              <div style={{color: 'red'}}>
                                <div style={{textAlign:'right'}}>
                                  TOTAL COUNT :
                                </div>
                                <div  style={{textAlign:'right'}}>
                                  PRICE PER SINGLE :
                                </div>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div style={{color: 'red'}}>
                              <div style={{textAlign:'left'}}>
                                {numberWithCommas(totalCount)}
                              </div>
                              <div  style={{textAlign:'left'}}>
                                {calcPricePerSinge(e, totalCount)}
                              </div>
                            </div>
                          </td>
                        </tr>
                      } else {
                        return null
                      }
                    })}
                  </tbody>
                </Table>
              </div>
            </div>
            }</div>
          </div>
        </div>
      </div>
  );
}

export default Craft;
